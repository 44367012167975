<template>
  <div class="shouyeLineEchart">
    <div class="title-box">销量金额</div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'

export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      provinceList: '',
      yearList: [],
      this_year_dosage: '',
      last_year_dosage: '',
      before_last_year_dosage: '',
      this_year_sale: [],
      last_year_sale: [],
      before_last_year_sale: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.provinceList = this.ehartData.provinceList
    this.this_year_dosage = this.ehartData.this_year_dosage
    this.last_year_dosage = this.ehartData.last_year_dosage
    this.before_last_year_dosage = this.ehartData.before_last_year_dosage
    this.this_year_sale = this.ehartData.this_year_sale
    this.last_year_sale = this.ehartData.last_year_sale
    this.before_last_year_sale = this.ehartData.before_last_year_sale

    this.yearList = this.ehartData.yearList
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },

    initEcharts() {
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (param)=> {
            let text = ''
            param.forEach((e,index)=> {
              if(index == 0){
                text += e.name +'<br\>'
              }
              text += `${e.marker}${e.seriesName}: ${this.$tool.moneyFormatter(e.value, 2) }元 <br\>`
            })
            return text
          }
        },
        legend: {
          data: this.yearList,
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
                interval:0,//代表显示所有x轴标签显示
            },
            data: this.provinceList,
          },
        ],
        yAxis: [
        
          {
            type: 'value',
						name: "单位（元）",
          },
        ],
        series: [
          {
            name: this.yearList[0],
            type: 'bar',
            barGap: 0,
            color: '#C0504D',
            data: this.before_last_year_sale,
          },
          {
            name: this.yearList[1],
            type: 'bar',
            barGap: 0,
            color: '#8064A2',
            data: this.last_year_sale,
          },
          {
            name: this.yearList[2],
            type: 'bar',
            barGap: 0,
            color: '#F79646',
            data: this.this_year_sale,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.title-box {
  font-size: px(18);
  font-weight: bold;
  color: #999999;
}
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .dw {
    font-size: 14px;
    color: #999999;
    position: absolute;
    right: 20px;
    top: 23px;
  }
  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>

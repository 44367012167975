<template>
  <div class="shouyeLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      targetList: [],
			saleList: [],
			finishList: [],
			xNameList: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.handleData()
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {
      this.targetList = this.ehartData.targetList
		  this.saleList = this.ehartData.saleList
		  this.finishList = this.ehartData.finishList
		  this.xNameList = this.ehartData.xNameList
    },
    initEcharts() {
      var that = this
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (value) =>{
						let text = '';
						value.forEach((e,index) => {
							if(index == 0){
								text += `${e.name}<br/>`
							}
							if(e.componentSubType == 'bar'){
								text += `${e.marker}${e.seriesName}: ${this.$tool.moneyFormatter(e.value,2)}万元<br/>`
							}else {
								text += `${e.marker}${e.seriesName}: ${this.$tool.moneyFormatter(e.value,2)}%<br/>`
							}
						})
						return text
					}
        },
				legend: {
				  data: ['目标金额','完成金额','完成比例'],
				},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.xNameList,
						triggerEvent: true,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
            }
          },
        ],
        yAxis: [
          {
            name: "单位（万元）",
            type: 'value',
          },
          {
            name: "%",
            type: 'value',
          },
        ],
        series: [
          {
						name: '目标金额',
            type: 'bar',
            data: this.targetList,
            yAxisIndex: 0,
							itemStyle: {
						    color: '#00BFBF'
						  },
          },
					{
						name: '完成金额',
					  type: 'bar',
					  data: this.saleList,
					  yAxisIndex: 0,
							itemStyle: {
						    color: '#7B4D12'
						  },
					},
          {
						name: '完成比例',
            type: 'line',
            data: this.finishList,
            yAxisIndex: 1,
						itemStyle: {
					    color: '#0433FF'
					  }
          }
        ],
      }
      this.myChart.setOption(option)
      this.myChart.on('click',(params) => {
        if(params.componentType == 'xAxis'){
          this.$emit("goDetails",params.value)
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>

<template>
  <div class="shouyeLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'sum', 'ehartData','title','colorList'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      let that = this
      let minsum = (this.sum/10000).toFixed(2);
      let dataValue = this.ehartData.filter(e => e.value>minsum);
      var option = {
       
        textStyle: {
          color: '#00AFE9',
        },
       
        tooltip: {
          trigger: 'item',
          // formatter: '{b} : {c} ({d}%)', // <br/>换行
          formatter: function(data){
              return `${data.data.name} : ${that.$tool.moneyFormatter(data.data.value,0)} (${(data.data.value/Number(that.sum)*100).toFixed(2)}%)`
          },
        },
        series: [
          {
            type: 'pie',
            radius: [55, 100],
            // roseType: 'angle',
           
            label: {
              normal: {
                show: true,
                position: 'center',
                color:'#4c4a4a',
                formatter: '{title|'+this.title+'}',
                rich: {
                  title:{
                        fontSize: 20,
                        fontFamily : "微软雅黑",
                        color:'#454c5c'
                    },
                  
                }
            },
            emphasis: {//中间文字显示
                show: true,
            }
            },
            data:dataValue,
          },
          {
							// name: 'Access From',
							type: 'pie',
							radius: [55,100],
							avoidLabelOverlap: true,
              itemStyle: {
                borderColor: '#fff',
                borderWidth: '2',
                // color: (params) => {
                //   return this.colorList[params.dataIndex]
                // },
              },
							label:{ // 饼图图形上的文本标签
								normal:{
									show:true,
									position:'outside', //标签的位置
									textStyle : {
										color: '#203152',	
										fontWeight : 'bold',
										fontSize : 16    //文字的字体大小
									},
									// formatter: [
									// 	'{value|{c}}',
									// 	'{name|{b}}'
									// ].join('\n'),
									formatter: (params)=>{
										// return `${this.$tool.moneyFormatter(params.value,0)}
										// ${params.name}`
										// return ['{value|'+this.$tool.moneyFormatter(params.value,0)+'}','{name|'+params.name+'}'].
                    return `{value|${params.name}: (${(params.value/Number(that.sum)*100).toFixed(2)}%) }`
									},
									rich: {
										value: {
											color: '#203152',
											fontSize: 12,
											lineHeight: 20,
										},
										name: {
											color: '#203152',
											fontSize: 12,
											fontWeight: '300',
											lineHeight: 20
										},
									},
								},
							},
							labelLine: {
								show: true,
                showAbove: true,
                length:50,
                zIndedx: 999,
                
								normal: {   //设置引导线长度
									show: true,
									length:50,
                  zIndedx: 999,
								}
							},
							data: dataValue,
						},
          // {
          //   type: 'pie',
          //   radius: [40, 110],
          //   center: ['50%', '60%'],
          //   // roseType: 'angle',
          //   itemStyle: {
          //     borderColor: '#fff',
          //     borderWidth: '2',
          //     color: (params) => {
          //       return this.colorList[params.dataIndex]
          //     },
          //   },
          //   label: {
          //     show: true,
          //     position: 'inside',
          //     // formatter: '{d}%',
          //     formatter: function(data){
          //       return (data.data.value/Number(that.sum)*100).toFixed(2)+'%'
          //     },
          //     textStyle: {
          //       fontWeight: 'bold',
          //       color: '#FAFAFA',
          //       fontSize: 16,
          //     },
             

          //   },
          //   data:this.ehartData,
          // },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .sum{
    font-size: px(23);
    color: #999999;

    position: absolute;
    right: px(20);
    top: px(15);
  }
  .dw {
    font-size: px(14);
    color: #999999;
    position: absolute;
    right: px(20);
    top: px(33);
  }
  .echarts {
    width: 100%;
    height: px(405);
 
  }
}
</style>

<template>
  <div class="shouyeLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      titlename: [],
      upValue: [],
      value: [],
      color: '',
      yLeft: 5,
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData();
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.handleData();
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {
      this.titlename = this.ehartData.titlename
      this.upValue = this.ehartData.upValue
      this.value = this.ehartData.value
      this.color =  this.ehartData.color
      // 深拷贝数据
      let sortCopy =[...this.titlename]
      // 求出y轴标签最长的项
      let maxlengthName = sortCopy.sort((a, b) => { 
          return b.length - a.length; 
      })[0];
      let num = 10 - this.titlename.length
      if(num>0){
        this.titlename= [...this.titlename,...new Array(num).fill('')];
        this.upValue= [...this.upValue,...new Array(num).fill('')];
      }
      this.yLeft = this.getTextWidth(maxlengthName,'13px Microsoft Yahei') + 60 
    },
    //通过canvas计算y轴文字的宽度
    getTextWidth(text, font) {
        let canvas = (document.createElement("canvas"));
        let context = canvas.getContext("2d"); 
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width;
    },

    initEcharts() {
      var that = this;
      var option = {
        xAxis: {
          show: false,
          boundaryGap: ['0%','20%'],
        },
        grid: {
          top: 10,
          bottom: 10,
          left: this.yLeft,
          right: 90,
        },
        yAxis: [
          {
            show: true,
            data: this.titlename,
            inverse: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#000',
              formatter: function (value, index) {
                if(value){
                  return `${
                  index == 0
                    ? '{lg1|1}'
                    : index == 1
                    ? '{lg2|2}'
                    : index == 2
                    ? '{lg3|3}'
                    : '{lg|' + (index + 1) + '}'
                  }   {title|${value}} `
                }else {
                  return ''
                }
              },
              textStyle:{
                align:'left',
                fontSize: 13,
              },
              margin: this.yLeft-20,
              rich: {
                lg1: {
                  backgroundColor: '#daa520',
                  color: '#fff',
                  borderRadius: 15,
                  align: 'center',
                  width: 15,
                  height: 15,
                },
                lg2: {
                  backgroundColor: '#c0c0c0',
                  color: '#fff',
                  borderRadius: 15,
                  align: 'center',
                  width: 15,
                  height: 15,
                },
                lg3: {
                  backgroundColor: '#B87333',
                  color: '#fff',
                  borderRadius: 15,
                  align: 'center',
                  width: 15,
                  height: 15,
                },
                lg: {
                  backgroundColor: '#bbb',
                  color: '#000',
                  borderRadius: 15,
                  align: 'center',
                  width: 15,
                  height: 15,
                },
              },
            },
          },
          {
            show: true,
            inverse: true,
            data: this.upValue,
            axisLabel: {
              textStyle: {
                fontSize: 12,
                color: '#000',
              },
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value) => {
                if(value == ''){
                  return ''
                }
                if(value>=0){
                  var valueText = value*100
                  return `{percent|${valueText<=100?valueText.toFixed(2):'100.00'}%}  {up|⬆}`
                }else {
                  var valueText = (Math.abs(value)*100)
                  return `{percent|${valueText<=100?valueText.toFixed(2):'100.00'}%} {down|⬇}`
                }
              },
              rich: {
                up: {
                  color: '#6EB501',
                  fontSize: '18',
                },
                down: {
                  color: 'red',
                  fontSize: '18',
                },
              }
            }
          },
        ],
        series: [
          {
            name: '条',
            type: 'bar',
            yAxisIndex: 0,
            data: this.value,
            itemStyle: {
              normal: {
                barBorderRadius: 3,
                color: this.color,
              },
            },
            barWidth: 13,
            showBackground: true,
            backgroundStyle: {
              borderColor: this.color,
              color: '#fff',
              borderWidth: 2,
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: (param)=> {
                  return that.$tool.moneyFormatter(param.value,0)
                },
                fontSize: 10,
              },
            },
          },
          // {
          //   name: '框',
          //   type: 'bar',
          //   yAxisIndex: 1,
          //   barGap: '-100%',
          //   data: [100, 100, 100, 100, 100,100,100,100,100,100],
          //   barWidth: 40,
          //   itemStyle: {
          //     normal: {
          //       color: 'none',
          //       borderColor: '#00c1de',
          //       borderWidth: 3,
          //       barBorderRadius: 15,
          //     },
          //   },
          // },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>

<template>
  <div class="index">
    <div class="box accumulate-box">
      <div v-for="(item, index) in accumulateList" :key="index" class="accumulate-box-item">
        <p>
          {{ item.name }}
        </p>
        <p >
          <span :style="index==0||index==1?'color:#02A7F0':'color: #00BFBF'">{{ item.sum }}</span>
          <span>{{ item.unit }}</span>
          <span :style="item.up?'color:#6EB501':'color:red'" class="icon-box">
            {{item.up?'⬆':'⬇'}}
          </span>
        </p>
        <div v-if="item.finish!==null" class="finish-box">
          <p class="finish-text-box"> 完成率<span>{{perConvert(item.finish)}}%</span></p>
          <p class="percentBorder">
            <span class="percentCenter" :style="'width:'+perConvertStyle(item.finish)+'%'"> </span>
          </p>
        </div>
      </div>
    </div>
    <div class="box pie-box">
      <p class="title-box">检测项目分布图</p>
      <div class="testing-pie-box"  v-loading="testingShow===null">
        <testingPie v-if="testingShow" class="testing-pie" id="testingYear" title="年度" :ehartData="testingYearData" :sum="sumYear" :colorList="colorListPie" ></testingPie>
        <testingPie v-if="testingShow" class="testing-pie" id="testingmonth" title="本月" :ehartData="testingMonthData" :sum="sumMonth" :colorList="colorListPie" ></testingPie>
        <!-- <platformPie v-if="testingShow" class="testing-pie" id="testingYear" title="年度" :ehartData="testingYearDataPlatform" :sum="sumYear" :colorList="colorListPie"></platformPie>
        <platformPie v-if="testingShow" class="testing-pie" id="testingmonth" title="月度" :ehartData="testingMonthDataPlatform" :sum="sumYear" :colorList="colorListPie"></platformPie> -->
        <span v-if="testingShow===false" class="no-data-tip">暂无数据</span>
        
      </div>
    </div>
   
    <div class="box saleTarget-box" id="saleTargetBox"  v-loading="saleTargetShow===null">
      <p class="title-box">各区域销售完成情况</p>
      <div class="saleTarget-center-box">
        <saleTargetBar v-if="saleTargetShow" id="saleTargetEcharts" :ehartData="saleTargetEchartData" @goDetails="goSaleDetails"></saleTargetBar>
        <span v-if="saleTargetShow===false" class="no-data-tip">暂无数据</span>
      </div>
    </div>
    <div class="box threeYear-box"  v-loading="threeYearShow===null">
      <p class="title-box">近三年各省用量数据统计</p>
      <div class="threeYear-center-box">
        <threeYearBar v-if="threeYearShow" id="threeYearEcharts" :ehartData="threeYearEchartData"></threeYearBar>
        <threeYearBarSale v-if="threeYearShow" id="threeYearEcharts2" :ehartData="threeYearEchartData"></threeYearBarSale>

        <span v-if="threeYearShow===false" class="no-data-tip">暂无数据</span>
      </div>
    </div>
    <div class="box outbound-box">
      <div class="outbound-top-box">
        <p class="title-box">{{outboundTitle}}</p>
        <div class="right">
          <el-radio-group v-model="outbound_radio" size="mini" @input="outboundYear=outboundMonth='';outboundDay=[];">
            <el-radio-button label="年"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="日"></el-radio-button>
          </el-radio-group>
          <div class="date-picker-box">
            <el-date-picker
              class="date-picker-year"
              v-show="outbound_radio==='年'"
              v-model="outboundYear"
              :editable="false"
              type="year"
              format="yyyy"
              value-format="yyyy"
              size="mini"
              placeholder="请选择年份"
              @change="changeOutboundData"
              :clearable="false"
            ></el-date-picker>
            <el-date-picker
              class="date-picker-month"
              v-show="outbound_radio==='月'"
              v-model="outboundMonth"
              type="month"
              :editable="false"
              format="yyyy/MM"
              value-format="yyyyMM"
              size="mini"
              placeholder="请选择月份"
              @change="changeOutboundData"
              :clearable="false"
            ></el-date-picker>
            <el-date-picker
              class="date-picker-day"
              v-show="outbound_radio==='日'"
              v-model="outboundDay"
              @change="changeOutboundData"
              size="mini"
              type="daterange"
              format="yyyy/MM/dd"
              value-format="yyyyMMdd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </div>
      </div>
      <div class="outbound-center-box"  v-loading="outboundShow===null">
        <outboundBar id="outboundBarEchaets" v-if="outboundShow" :ehartData="outboundEchartData"></outboundBar>
        <span v-if="outboundShow===false" class="no-data-tip">暂无数据</span>
      </div>
    </div>
    <div class="topTen-box">
      <div class="topTen-top-box">
        <div class="left">
          <p v-for="(item,index) in TopTenTypeList" :key="index" :class="item.value===topTen_type&&'active-lable'" @click="changeTopTenType(item)">
            {{item.name}}
          </p>
        </div>
        <div class="right">
          <p v-for="(item,index) in TopTenTimeList" :key="index" :class="item.value===topTen_time&&'active-lable'" @click="changeTopTenTime(item)">
            {{item.name}}
          </p>
          <el-popover
            v-model="topTenPopoverShow"
            placement="bottom"
            width="400"
            trigger="click">
            <div class="dataPick-box">
              <el-date-picker
                size="mini"
                v-model="topTenDay"
                :clearable="false"
                type="daterange"
                format="yyyy/MM/dd"
                value-format="yyyyMMdd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <el-button class="dataPick-btn" :disabled="topTenDay[0]==''" size="mini" @click="confirmTopTenData">确定</el-button>
            </div>
            <p slot="reference" :class="topTen_time===0&&'active-lable'" @click="topTen_time=0">自定义时间</p>
          </el-popover>
        </div>
      </div>
      <div class="box topTen-center-box"  v-loading="toptenShow===null">
        <topTenBar v-if="toptenShow" id="topTenBarEcharts" :ehartData="topTenEchartData"></topTenBar>
        <span v-if="toptenShow===false" class="no-data-tip">暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import { eventBus } from '@/main'
import testingPie from './components/echarts/testingPie.vue'
import platformPie from './components/echarts/platformPie.vue'
import topTenBar from './components/echarts/topTenBar.vue'
import outboundBar from './components/echarts/outboundBar.vue'
import threeYearBar from './components/echarts/threeYearBar.vue'
import threeYearBarSale from './components/echarts/threeYearBarSale.vue'
import saleTargetBar from './components/echarts/saleTargetBar.vue'
export default {
  name: 'dataBoard',
  components: {
    testingPie,platformPie,topTenBar,outboundBar,threeYearBar,threeYearBarSale,saleTargetBar
  },
  mixins: [],
  props: {},
  data() {
    return {
      nowDate: '',//当前日期
      accumulateList: [
        {
          name: '年度累计销售额',
          sum: '0.00',
          unit: '元',
          finish: 0,
          up: true,
        },
        {
          name: '年度累计人份数',
          sum: '0',
          unit: '人份',
          finish: null,
          up: true,
        },
        {
          name: '本月累计销售额',
          sum: '0.00',
          unit: '元',
          finish: 0,
          up: true,
        },
        {
          name: '本月累计人份数',
          sum: '0',
          unit: '人份',
          finish: null,
          up: true,
        },
      ],
      testingShow: null,
      testingYearData: [],
      testingMonthData: [],
      testingYearDataPlatform: [],
      testingMonthDataPlatform: [],
      sumYear: 4,
      sumMonth: 4,
      colorListPie: [
        "#0070BE",
        "#70309D",
        "#DE0001",
        '#00AFE9',
        '#1ACB64',
        '#F77490',
        '#F9C942',
        '#2C52F1',
      ],
      
      outboundTitle: '',
      outbound_radio: '年',
      outboundYear: '',
      outboundMonth: '',
      outboundDay: [],
      outboundEchartData: {},
      outboundShow: false,

      threeYearShow: null,
      threeYearEchartData: {},

      saleTargetData: [],
      saleTargetShow: null,
      saleTargetEchartData: {},

      topTen_type: 'province',
      TopTenTypeList: [
        {
          name: '区域用量TOP10',
          value: 'province',
        },
        {
          name: '医院用量TOP10',
          value: 'hospital',
        },
        {
          name: '仪器用量TOP10',
          value: 'instrument',
        },
      ],
      topTenPopoverShow: false,
      topTenDay: ['',''],
      topTen_time: 7,
      TopTenTimeList: [
        {
          name: '近7日',
          value: 7,
        },
        {
          name: '近15日',
          value: 15,
        },
        {
          name: '近30日',
          value: 30,
        },
      ],
      toptenShow: null,
      topTenEchartData: {},
    }
  },
  computed: {
    perConvert() {
			return (per)=>{
				return (Number(per)*100).toFixed(2)
			}
		},
    perConvertStyle() {
			return (per)=>{
				return Number(per)>=1?100:(Number(per)*100).toFixed(2)
			}
		}
  },
  watch: {},
  created() {
    eventBus.$on('scrollView',(value) => {
      document.querySelector('#saleTargetBox').scrollIntoView({
        });
    })
    /* 
      获取当前时间 当前时间 yyyy-MM-DD
    */
    const nowDate = new Date()
    let mouth = nowDate.getMonth() + 1,
      day = nowDate.getDate()
    this.nowDate = `${nowDate.getFullYear()}-${mouth <= 9 ? '0' + mouth : mouth}-${day <= 9 ? '0' + day : day}`
    this.topTenDay = [`${nowDate.getFullYear()}${mouth <= 9 ? '0' + mouth : mouth}01`,this.nowDate.split('-').join('')]
  },
  beforeDestroy(){
    //移除监听事件"msg"
    eventBus.$off("scrollView")
  },
  mounted() {
    this.get_tbdaily_trade_entire();
    this.get_topTen();
    this.get_last_three_year_tbdosage();
    this.get_sale_target();
    this.outboundYear = this.nowDate.split('-')[0]
    this.changeOutboundData();
    // 判断上一页位置，如果是从销售详情也过来，则滚动到各区域销售完成情况
 
  },
  methods: {
    // 时间倒退几天
    returnday(i,date=new Date()) {
      //data是日期，i是往前推多少天
      let newDate = new Date(date.getTime() - i * 1000 * 60 * 60 * 24);
      let year = newDate.getFullYear();
      let month =
        parseInt(newDate.getMonth()) + 1 > 9
          ? parseInt(newDate.getMonth()) + 1
          : "0" + (parseInt(newDate.getMonth()) + 1);
      let day =
        newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate();
      let fullDate = `${year}${month}${day}`;
      // console.log(fullDate);
      return fullDate;
    },
    // 获取年度和本月销售情况
    get_tbdaily_trade_entire() {
      this.testingShow = null;
      api.tbdaily_trade_entire(
        // {start_day:this.nowDate.split('-').join('')}
      ).then(res=> {
        if(res.code == 200){
          let year = res.data.year
          let month = res.data.month
          this.accumulateList[0].sum = year.orders.sum?this.$tool.moneyFormatter(year.orders.sum,2):0
          this.accumulateList[0].up = year.orders.up
          this.accumulateList[0].finish = year.orders.finish
          this.accumulateList[1].sum = year.amount.sum?this.$tool.moneyFormatter(year.amount.sum,0):0
          this.accumulateList[1].up = year.amount.up
          this.accumulateList[2].sum = month.orders.sum?this.$tool.moneyFormatter(month.orders.sum,2):0
          this.accumulateList[2].up = month.orders.up
          this.accumulateList[2].finish = month.orders.finish
          this.accumulateList[3].sum = month.amount.sum?this.$tool.moneyFormatter(month.amount.sum,0):0
          this.accumulateList[3].up = month.amount.up
        
          this.sumYear = year.dosage_total
          this.testingYearData = year.dosage.map(e => {
            return {name:e.testing_unique,value: e.cnt}
          })
          this.sumMonth = month.dosage_total
          this.testingMonthData = month.dosage.map(e => {
            return {name:e.testing_unique,value: e.cnt}
          })
          if(this.testingYearData.length==0&&this.testingMonthData.length==0){
            this.testingShow = false;
          }else {
            this.testingShow = true;
          }

          this.testingYearDataPlatform = year.dosage_by_platform.map(e => {
            return {
              name: e.platform_unique,
              value: e.total,
              items: e.items
            }
          })
          this.testingMonthDataPlatform = month.dosage_by_platform.map(e => {
            return {
              name: e.platform_unique,
              value: e.total,
              items: e.items
            }
          })
        }
      })
    },
    // 选中日期
    changeOutboundData() {
      let params = {}
      switch (this.outbound_radio) {
        case '年':
          params = {
            start_day: `${this.outboundYear}0101`,
            end_day: `${this.outboundYear}1231`,
          }
          this.outboundTitle = this.outboundYear +'年各区域出库数据汇总'
          break;
        case '月':
          this.outboundTitle = `${this.outboundMonth.substring(0,4)}年${Number(this.outboundMonth.substring(4,6))}月各区域出库数据汇总`
          let year = Number(this.outboundMonth.substring(0,4))
          let month = Number(this.outboundMonth.substring(4,6))
          let day = new Date(year,month,0).getDate();
          params = {
            start_day: `${this.outboundMonth}01`,
            end_day: this.outboundMonth+day,
          }
          break;
        case '日':
          params = {
            // type: 'day',
            start_day: this.outboundDay[0],
            end_day: this.outboundDay[1],
          }
          this.outboundTitle = `${params.start_day.substring(0,4)}年${Number(params.start_day.substring(4,6))}月${Number(params.start_day.substring(6,8))}日至${params.end_day.substring(0,4)}年${Number(params.end_day.substring(4,6))}月${Number(params.end_day.substring(6,8))}日各区域出库数据汇总`
          break;
      
        default:
          break;
      }
      this.get_area_orders(params)
    },
    // 统计同比销售省份人份数列表
    get_area_orders(params){
      this.outboundShow = null;
      api.area_orders(params).then(res => {
        if(res.code === 200){
          if(res.data.length==0){
            this.outboundShow = false;
            return
          }
          let orderSumList = [],
              orderNumList = [],
              provincelist = [];
          res.data.forEach(e => {
            orderNumList.push(e.orders_num)
            orderSumList.push(e.orders_sum)
            provincelist.push(e.province_name)
          })
          this.outboundEchartData = {
            orderSumList,provincelist,orderNumList
          }
          this.$nextTick(()=> {
            this.outboundShow = true;
          })
        }
      })
    },
    // 获取业绩目标展示
    get_sale_target() {
      this.saleTargetShow = null
      api.sale_target().then((res)=> {
        if(res.code === 200){
          this.saleTargetData = res.data;
          let targetList = [],
						saleList = [],
						finishList = [],
						xNameList = [];
					res.data.forEach(e => {
						targetList.push(e.year_total)
						saleList.push(e.sale)
						finishList.push((Number(e.finish) * 100).toFixed(2))
						if (e.user_name) {
							xNameList.push(e.user_name)
						} else {
							xNameList.push(e.province)
						}
					})
					this.saleTargetEchartData = {
						targetList,
						saleList,
						finishList,
						xNameList
					}
          if(this.saleTargetEchartData.targetList.length!=0){
            this.$nextTick(()=> {
              this.saleTargetShow = true;
            })
          }else {
            this.saleTargetShow = false;
          }
        }
      })
    },
    // 跳转到业绩目标详情
    goSaleDetails(value) {
      let query = {
        query: JSON.stringify(this.saleTargetData.find(e => e.user_name == value || e.province == value)),
        nowDate: this.nowDate,
      }
      this.$router.push({ 
        path: '/saleTarget',
        query,
      })
    },
    // 统计同比近三年各省用量
    get_last_three_year_tbdosage(){
      this.threeYearShow = null
      api.last_three_year_tbdosage().then(res => {
        if(res.code == 200) {
          let this_year_dosage = [],
              last_year_dosage = [],
              before_last_year_dosage = [],
              this_year_sale = [],
              last_year_sale = [],
              before_last_year_sale = [],
              provinceList = []
          res.data.forEach(e=> {
            provinceList.push(e.province_name)
            this_year_dosage.push(e.this_year.dosage)
            last_year_dosage.push(e.last_year.dosage)
            before_last_year_dosage.push(e.before_last_year.dosage)

            this_year_sale.push(e.this_year.sale)
            last_year_sale.push(e.last_year.sale)
            before_last_year_sale.push(e.before_last_year.sale)
            // count_sum_list.push(e.count_sum)
            // last_year.push(e.last_year)
            // before_last_year.push(e.before_last_year)
          })

          let nowYear = this.nowDate.split('-')[0]
          let yearList = [(Number(nowYear)-2)+'',(Number(nowYear)-1)+'',nowYear]
          this.threeYearEchartData = {
            this_year_dosage,
            last_year_dosage,
            before_last_year_dosage,
            this_year_sale,
            last_year_sale,
            before_last_year_sale,
            
            provinceList,
            yearList
          }
          if(this.threeYearEchartData.this_year_dosage.length!=0){
            this.$nextTick(()=> {
              this.threeYearShow = true
            })
          }else {
            this.threeYearShow = false
          }
          
        }
      })
    },
    // 修改top类型
    changeTopTenType(item) {
      if(item.value !== this.topTen_type){
        this.topTen_type = item.value
        this.get_topTen();
      }
    },
    // 修改时间类型
    changeTopTenTime(item) {
      if(item.value !== this.topTen_time){
        this.topTen_time = item.value
        this.get_topTen();
      }
    },
    // 确定自定义时间
    confirmTopTenData() {
      let startYear = this.topTenDay[0].substring(0,4)
      let endYear = this.topTenDay[1].substring(0,4)
      if(startYear!=endYear){
        this.$message.warning('日期范围请不要跨年')
        return
      }
      this.topTenPopoverShow = false
      this.get_topTen();
    },
    // 获取前十数据
    get_topTen() {
      let params = {}
      if(this.topTen_time == 0){
        params = {
          start_day: this.topTenDay[0],
          end_day: this.topTenDay[1],
          type: this.topTen_type,
        }
      }else {
        params = {
          time: this.topTen_time,
          end_day: this.returnday(1),
          type: this.topTen_type,
        }
      }
      this.toptenShow = null;
      api.top(params).then(res => {
        if(res.code == 200) {
          let titlename = [],
            upValue = [],
            value = [],
            color = ''
          switch (params.type) {
            case 'province':
              res.data.forEach(e => {
                titlename.push(e.province_name)
                upValue.push(e.up)
                value.push(e.count_sum)
              });
              color = '#BFBF00'
              break;
            case 'hospital':
              res.data.forEach(e => {
                titlename.push(e.hospital_name)
                upValue.push(e.up)
                value.push(e.count_sum)
              });
              color = '#00BFBF'
              break;
            case 'instrument':
              res.data.forEach(e => {
                titlename.push(`${e.hospital_name} ${e.instrument}/${e.platform_unique}`)
                upValue.push(e.up)
                value.push(e.count_sum)
              });
              color = '#B8741A'
              break;
            default:
              break;
          }
          if(value.length == 0){
            this.toptenShow = false;
            return
          }
          this.topTenEchartData = {
            titlename,upValue,value,color,
          }
          this.$nextTick(()=> {
            this.toptenShow = true;
          })
        }
       

      })
    },
  },
}
</script>
<style lang='scss' scoped>
  $gap: px(10);

.index {
  padding: $gap;
}
.box {
  background-color: #fff;
  padding: $gap;
  margin: $gap;
  border-radius: $gap/2;
  border: px(1) #eee solid;
}
.accumulate-box {
  display: flex;
  justify-content: space-between;
  .accumulate-box-item {
    flex: 1;
    text-align: center;
    .icon-box {
      font-weight: bold;
    }
    >p:nth-child(1){
      font-size: px(18);
      color: #444;
      font-weight: bold;
      font-family: "黑体", "微软雅黑", sans-serif;
      margin-top: 16px;
    }
    >p:nth-child(2){
      font-size: px(30);
      margin: px(20) px(20) px(5);
      color: #444;
      span:nth-child(1){
        font-weight: bold;
        font-family: "黑体", "微软雅黑", sans-serif;
      }
      span:nth-child(2){
        margin: 0 px(5);
        font-size: px(16);
        color: #444;
        font-weight: bold;
        font-family: "黑体", "微软雅黑", sans-serif;
      }
      span:nth-child(3){
        font-weight: bold;
      }
    }
   
    .finish-box {
      .finish-text-box {
        text-align: left;
        margin-left: px(20);
        font-size: px(14);
        color: #999999;
        >span {
          margin-left: px(3);
          color:#70B603;
        }
      }
      .percentBorder {
        margin: px(10) px(20) px(20);
        height: 5px;
        border: px(1) solid #70B603;
        border-radius: 10px;
        width: calc(100% - px(40)) ;
        .percentCenter {
          display: block;
          background-color: #70B603;
          border-radius: 10px;
          height: 5px;
        }
      }
    }
  }
}
.pie-box {
  &>p {
    text-align: center;
    margin-top: 14px;
  }
  .testing-pie-box {
    position: relative;
    display: flex;
    min-height: px(200);
    .testing-pie {
      flex: 1;
    }
  }
}
.outbound-box {
  .outbound-top-box {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      &>div {
        margin-left: px(10);
      }
    }
    
  }
  .date-picker-box {
      ::v-deep.el-input .el-input__inner{
        width: 100%;
      }
    .date-picker-day {
      ::v-deep.el-range-input{
        font-size: 0.833vw !important;
      }
    }
  }
  .outbound-center-box {
    position: relative;
    height: px(420);
  }
}
.saleTarget-box {
  .saleTarget-center-box {
    position: relative;
    height: px(420);
  }
}
.threeYear-box {
  .threeYear-center-box {
    position: relative;
    height: px(900);
  }
}
.topTen-box {
  
  .topTen-top-box {
    display: flex;
    margin: $gap $gap 0;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      &>p {
        background-color: #fff;
        padding: $gap;
        border: px(1) #eee solid;
        font-size: px(16);
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: px(30);
      p {
        background-color: #fff;
        padding: $gap/2 $gap;
        border: px(1) #eee solid;
        font-size: px(14);
        cursor: pointer;
      }
    }
    &>div .active-lable {
      background-color: #02A7F0 ;
      color: #fff;
    }
  }
  .topTen-center-box {
    margin-top: 0;
    height: px(420);
    position: relative;
    
  }
}
.no-data-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  transform: translate(-50%,-50%);
}
.dataPick-box {
  display: flex;
  .dataPick-btn {
    margin-left: px(20);
  }
}
.title-box {
  font-size: px(20);
  font-weight: bold;
  padding:10px;
}
</style>
<template>
  <div class="shouyeLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'
export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      orderNumList: [],
      orderSumList: [],
      provincelist: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.handleData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.handleData()
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    // 数据初步处理
    handleData() {
      this.orderNumList = this.ehartData.orderNumList
      this.orderSumList = this.ehartData.orderSumList
      this.provincelist = this.ehartData.provincelist
    },
    initEcharts() {
      var that = this
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (param)=> {
            let text = ''
            param.forEach((e,index)=> {
              if(index == 0){
                text += `${e.name}<br/>`
              }
              if(e.componentSubType == 'bar'){
                text += `${e.marker}${e.seriesName}: ${this.$tool.moneyFormatter(e.value, 2)}元<br/>`
              }else {
                text += `${e.marker}${e.seriesName}: ${this.$tool.moneyFormatter(e.value, 0)}个<br/>`
              }
            })
            return text
          }
        },
        legend: {
          data: ['销售金额','订单数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
       
        xAxis: [
          {
            type: 'category',
            data: this.provincelist,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            name: "单位（元）",
            type: 'value',
          },
          {
            name: "单位（个）",
            type: 'value',
          },
        ],
        series: [
          {
            name: '销售金额',
            type: 'bar',
            barWidth: '60%',
            data: this.orderSumList,
            yAxisIndex: 0,
            itemStyle: {
              color: '#02A7F0'
            },
          },
          {
            name: '订单数',
            type: 'line',
            data: this.orderNumList,
            yAxisIndex: 1,
            itemStyle: {
              color: '#B8741A'
            },
          }
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>
